.cartContent {
    .titleCart {
        font-size: 20px !important;
        font-weight: 800 !important;
        color: #333333;
        margin-bottom: 15px;
    }

    .cartInfoBlock {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 30px;
    }
}

.registerPage,
.loginPage {
    // padding: 10px 0 90px; original
    // padding: 70px 0 70px; modal
    padding: 20px 0 20px; // popup

    .titleBox {
        position: relative;
        padding: 0 0 40px;
        margin-bottom: 15px;
        text-align: center;

        .loginTitle {
            z-index: 1;
            position: relative;
        }

        .bigTitle {
            font-family: 'Gilroy-Light';
            font-weight: 100;
            color: #EBEBEB;
            letter-spacing: -4px;
            margin-bottom: -80px;

            @media (max-width: 768px) {
                display: none;
            }
        }

        @media (max-width: 768px) {
            padding: 0;
        }
    }

    .loginOption {
        display: flex;
        background-color: #ffffff;
        border-radius: 5px;
        align-items: center;
        padding: 10px;
        margin-bottom: 20px;

        .MuiTypography-body1 {
            font-size: 12px;
            color: #333333;
        }
    }

    .btnForgetPass {
        font-size: 12px;
        color: #777777;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .btnLogin {
        font-family: 'Manrope';
        font-size: 17px;
        font-weight: 800;
        color: #ffffff;
        background-color: #FF367D;
        padding: 10px;
        width: 100%;
        text-transform: none;

        &:hover {
            background-color: #FF367D;
        }
    }

    .registerAction {
        font-family: 'Manrope';
        font-size: 12px;
        color: #777777;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;

        .btnLink {
            font-size: 12px;
            font-weight: 800;
            color: #0084F4;
            text-transform: none;

            &::after {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                background-color: transparent;
                border-top: 1px solid #0084f4;
                border-right: 1px solid #0084f4;
                transform: rotate(40deg);
                margin-left: 5px;
            }
        }
    }

    .infoRegisterBlock {
        display: flex;
        align-items: center;

        .MuiInput-formControl {
            margin: 0 15px;

            @media (max-width: 768px) {
                margin: 0;
            }
        }

        .labelBlock {
            @media (max-width: 768px) {
                margin-bottom: 10px;
            }
        }

        @media (max-width: 768px) {
            display: block;
        }
    }

    .taxRegimeBlock {
        display: flex;

        @media (max-width: 768px) {
            display: block;
        }
    }
}

.switch {
    .MuiSwitch-root {
        padding: 12px 14px;

        .MuiSwitch-switchBase {
            padding: 10px 14px;
            color: #C9C9C9;

            &.Mui-checked {
                transform: translateX(12px);
                color: #db1842;
            }
        }

        .MuiSwitch-thumb {
            width: 18px;
            height: 18px;
        }

        .MuiSwitch-track {
            background-color: #E4E4E4;
            opacity: 1;
        }

        .MuiIconButton-root {
            &:hover {
                background-color: transparent;
            }
        }
    }
}

.checkboxIE {
    .MuiFormControlLabel-root {
        .MuiButtonBase-root {
            .MuiIconButton-label {
                .MuiSvgIcon-root {
                    display: none;
                }

                &:after {
                    content: '';
                    display: block;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    background-color: transparent;
                    border: 1px solid #C9C9C9;
                }
            }

            &.Mui-checked {
                .MuiIconButton-label {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        height: 4px;
                        width: 8px;
                        background-color: transparent;
                        border-bottom: 2px solid #ffffff;
                        border-left: 2px solid #ffffff;
                        position: absolute;
                        top: 7px;
                        left: 6px;
                        transform: rotate(-45deg);
                    }

                    &:after {
                        background-color: #0084F4;
                        border: 1px solid #0084F4;
                    }
                }
            }
        }
    }
}

.itemListCheckout {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 0px 40px;

    @media (max-width: 600px) {
        padding: 0 20px;
    }

    .itemCheckout {
        &:not(:last-child) {
            border-bottom: 1px solid #E4E4E4;
        }

        padding: 40px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 600px) {
            display: block;
            padding: 16px 0;
        }

        .itemAction {
            @media (max-width: 600px) {
                margin: 0;
            }
        }

        .MuiFormControlLabel-root {
            .MuiFormControlLabel-label {
                padding-left: 5px;
            }

            .MuiButtonBase-root {
                .MuiIconButton-label {
                    div {
                        .MuiSvgIcon-root {
                            display: none;
                        }

                        &:after {
                            content: '';
                            display: block;
                            height: 17px;
                            width: 17px;
                            border-radius: 50%;
                            background-color: transparent;
                            border: 1px solid #C9C9C9;
                        }
                    }
                }

                &.Mui-checked {
                    .MuiIconButton-label {
                        div {
                            position: relative;

                            &:before {
                                content: '';
                                display: block;
                                height: 8px;
                                width: 8px;
                                background-color: #0084F4;
                                border-radius: 50%;
                                position: absolute;
                                top: 6px;
                                left: 6px;
                                transform: rotate(-45deg);
                            }

                            &:after {
                                height: 16px;
                                width: 16px;
                                border: 2px solid #0084F4;
                            }
                        }
                    }
                }
            }

            .MuiFormControlLabel-label {
                font-weight: 800;
                color: #0084F4;
            }
        }

        .editButton {
            font-family: 'Manrope';
            font-size: 11px;
            font-weight: 800;
            color: #777777;
            border-radius: 5px;
            border: 2px solid #777777;
            padding: 7px 12px;

            .icon {
                margin-right: 10px;
            }
        }

        .deleteButton {
            .icon {
                margin: 0;
            }

            &:hover {
                background-color: transparent;
            }
        }

        .infoDiscount {
            font-size: 10px;
            font-weight: 800;
            text-transform: uppercase;
            color: #ff367d; // #db1842;
            background-color: rgba(220, 24, 67, 0.1);
            border-radius: 4px;
            width: max-content;
            padding: 7px 10px;
            margin-left: 20px;

            @media (max-width: 600px) {
                margin-top: 5px;
                margin-left: 0px !important;
            }
        }

        &.itemCheckoutPayment {
            border: 0;
        }

        &.discountCoupon {
            @media (max-width: 600px) {
                display: flex;
            }
        }
    }

    .itemCheckoutTitle {
        font-size: 20px;
        font-weight: 800;
        color: #333333;

        @media (max-width: 600px) {
            font-size: 14px;
        }
    }

    .payment-option-title {
        display: flex;
        align-items: flex-start;
        margin-left: 5px;

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }

    .payment-price {
        @media (max-width: 600px) {
            font-weight: 800;
            color: #333333;
            margin-left: 36px;
            margin-top: 10px;
        }
    }

    .body1Bold {
        font-weight: 800;
        color: #333333;
    }

    .billetInfo {
        padding-left: 20px;
        position: relative;
        margin-bottom: 7px;

        &::before {
            content: '';
            display: block;
            width: 5px;
            height: 5px;
            background-color: #333333;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            left: 0;
        }
    }

    .titleNubank {
        font-size: 16px;
        font-weight: 800;
        margin-bottom: 12px;
        color: #333333;
    }

    .itemCheckoutNubank {
        border: 1px solid;
        border-color: rgb(130, 10, 209);
        border-radius: 1rem;
        background-color: #fff !important;
    }

    .nubankInfo {
        padding-left: 20px;
        position: relative;
        margin-bottom: 7px;

        &::before {
            content: '';
            display: block;
            width: 5px;
            height: 5px;
            background-color: #333333;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            left: 0;
        }
    }

    .nubankStrong {
        font-size: 14px;
        font-weight: 600;
        font-family: 'Manrope';
        line-height: 1.5;
        letter-spacing: 0.00938em;
        margin-top: 0.5rem;
    }

    .nubankIcon {
        width: 125px;
        margin-bottom: 0.5rem;
    }

    .boxTotalNupay {
        background-color: #ffffff;
        border-color: rgb(130, 10, 209);
        padding: 15px 30px;
        display: flex;
        align-items: center;
        text-align: center;
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);
        margin-top: 0.5rem;

        .block {
            width: 50%;
        }
    }

    .boxTotalBillet {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 15px 30px;
        display: flex;
        align-items: center;
        text-align: center;
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);

        .block {
            width: 50%;
        }

        @media (max-width: 600px) {
            padding: 10px 15px;
        }
    }

    .itemCheckoutBorder {
        padding-bottom: 20px;

        &:not(:last-child) {
            border-bottom: 1px solid #E4E4E4;
        }

        .paymentInfo {
            margin-bottom: 20px;
            background-color: #F4F4F4;
            padding: 40px;

            &.rccs__card {
                @media (max-width: 600px) {
                    transform: scale(0.6);
                }
            }
        }
    }

    .rccs {
        width: 100%;
        max-width: 290px;

        .rccs__card {
            width: 100%;
            max-width: 290px;
            max-height: 182.873px;

            @media (max-width: 410px) {
                .rccs__number {
                    font-size: 18px;
                }

                .rccs__name {
                    font-size: 15px;
                }

                .rccs__expiry__value {
                    font-size: 14px;
                }
            }
        }
    }

    .addressNew {
        padding: 30px 0;

        @media (max-width: 600px) {
            padding: 24px 0;
        }
    }

    .formAddress {
        background-color: #F4F4F4;
        border-radius: 5px;
        padding: 30px 40px 40px;

        .MuiFormControl-root {
            margin-bottom: 15px;
        }

        .formAddressTitle {
            font-size: 20px;
            font-weight: 800;
            color: #0084F4;
            border-bottom: 1px solid #E4E4E4;
            padding-bottom: 8px;
            margin-bottom: 20px;
        }

        .cepLink {
            color: #C9C9C9;
        }

        @media (max-width: 600px) {
            padding: 16px;
        }
    }
}

.ordersList {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 40px;

    @media (max-width: 600px) {
        padding: 20px 15px;
    }

    .orderItem {
        border-bottom: 1px solid #E4E4E4;
        padding-top: 30px;
        padding-bottom: 30px;

        .orderBlock {
            display: flex;
            justify-content: space-between;

            @media (max-width: 600px) {
                display: block;
            }

            .block {
                .numberOrder {
                    font-size: 20px;
                    font-weight: 800;
                    color: #333333;
                }

                .statusOrder {
                    font-size: 9px;
                    font-weight: 800;
                    text-transform: uppercase;
                    border-radius: 4px;
                    width: max-content;
                    padding: 5px 10px;
                    margin-left: 20px;

                    &.transport {
                        color: #0084F4;
                        background-color: #D5E9FA;
                    }

                    &.paymentDenied {
                        color: #FF7C2F;
                        background-color: #FFE8DA;
                    }
                }

                .detailsOrder {
                    border-left: 2px solid #E4E4E4;
                    padding-left: 15px;

                    .detailsOrderItem {
                        color: #777777;
                        display: block;
                        margin-bottom: 3px;
                    }
                }

                .btnShowOrder {
                    font-family: 'Manrope';
                    font-size: 12px;
                    font-weight: 800;
                    color: #777777;
                    border: 2px solid #777777;
                    border-radius: 6px;

                    .icon {
                        margin-right: 10px;
                    }

                    @media (max-width: 600px) {
                        margin-top: 20px;
                    }
                }
            }
        }

        .orderInfo {
            background-color: #F4F4F4;
            border-radius: 5px;
            padding: 40px;
            margin: 10px 0;

            @media (max-width: 600px) {
                padding: 30px;
            }

            .border-top {
                border-top: 1px solid #656D7826;
            }

            .block-items {
                padding: 30px 0 !important;
            }

            .block {
                border-bottom: 1px solid #656D7826;
                padding: 20px 0;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    border-bottom: 0;
                    padding-bottom: 0;
                }

                .productMedia {
                    width: 160px;
                    height: 100px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                .btnGroupQty {
                    margin-right: 50px
                }

                .btnQty {
                    font-family: 'Gilroy-ExtraBold';
                    font-weight: 100;
                    border: 0;
                    background-color: #f4f4f4;
                    padding: 7px 12px;
                    min-width: auto;
                }

                .cartItemInfo {
                    padding: 20px 0;
                }

                .productCod {
                    font-size: 9px;
                    text-transform: uppercase;
                    font-weight: 800;
                }

                .productName {
                    font-size: 20px;
                    font-weight: 800;
                    color: #333333;
                }

                .settingsButton {
                    font-family: 'Manrope';
                    font-size: 12px;
                    text-transform: none;
                    color: #0084F4;
                    padding: 4px 0 0;

                    &:hover {
                        background-color: transparent;
                    }
                }

                .featureItems {
                    border-left: 2px solid #E4E4E4;
                    padding-left: 15px;
                }

                .featureItem {
                    color: #777777;
                    display: block;
                    margin-bottom: 3px;
                }
            }
        }

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}