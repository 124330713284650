.categories-block {
    position: relative;
    background-color: #ffffff;

    .item-category {
        @media (max-width: 960px) {
            margin: -4px -24px;
        }

        @media (max-width: 600px) {
            margin: -4px -16px;
        }

        .item-toggle {
            width: 100%;
            position: relative;
            padding: 30px 0;
            cursor: pointer;
            justify-content: center;

            text-transform: none;

            @media (max-width: 960px) {
                justify-content: flex-start;
            }

            &.active {
                background-color: #F4F4F4;
            }

            .block-text {
                padding: 0 15px;
                text-align: center;

                @media (max-width: 960px) {
                    text-align: left;
                }
            }

            @media (max-width: 960px) {
                border-bottom: 1px solid #f4f4f4;
            }

            .border {
                height: 5px;
                width: 100%;
                position: absolute;
                top: 0;
                display: block;

                @media (max-width: 960px) {
                    height: 100%;
                    width: 5px;
                }

                @media (max-width: 600px) {
                    top: 0;
                    left: 0;
                    bottom: 0;
                }

                &.border1 {
                    background-color: #a10f32;
                }

                &.border2 {
                    background-color: #dc1d46;
                }

                &.border3 {
                    background-color: #fc6a89;
                }

                &.border4 {
                    background-color: #76B900;
                }
            }

            .title {
                font-family: 'Manrope';
                font-weight: 700;
                font-size: 22px;
                display: block;

                @media (max-width: 600px) {
                    font-size: 20px;
                }
            }

            .text {
                font-family: 'Manrope';
                font-weight: 400;
                font-size: 13px;
                display: block;
            }
        }

        .item-block {
            position: absolute;
            top: 120px;
            right: 0;
            left: 0;
            border-top: 1px solid #f4f4f4;
            background-color: #ffffff;
            padding: 30px 0;

            @media (max-width: 960px) {
                position: relative;
                top: inherit;
                right: inherit;
                left: inherit;
                border-top: 0;

                .border {
                    height: 5px;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    display: block;

                    @media (max-width: 960px) {
                        height: 100%;
                        width: 5px;
                    }

                    &.border1 {
                        background-color: #a10f32;
                    }

                    &.border2 {
                        background-color: #dc1d46;
                    }

                    &.border3 {
                        background-color: #fc6a89;
                    }

                    &.border4 {
                        background-color: #76B900;
                    }
                }
            }

            @media (max-width: 600px) {
                padding: 0;
            }

            .items {
                display: flex;

                @media (max-width: 960px) {
                    flex-wrap: wrap;
                }

                .item {
                    font-family: 'Manrope';
                    font-size: 20px;
                    font-weight: 800;
                    text-transform: none;
                    background-color: #F4F4F4;
                    border-radius: 5px;
                    height: 80px;
                    margin-right: 10px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (max-width: 960px) {
                        width: auto;
                        padding: 0 20px;
                        margin-bottom: 10px;
                    }

                    @media (max-width: 600px) {
                        font-size: 14px;
                        font-weight: 400;
                        border-bottom: 1px solid #EBEBEB;
                        background-color: #ffffff;
                        width: 100%;
                        justify-content: flex-start;
                        border-radius: 0;
                        margin-bottom: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.product-slider-home {
    .product-slider {
        padding: 70px 0;
        margin-right: -16px;

        .swiper-slide {
            width: 100% !important;

            &.swiper-slide-active {
                width: 90% !important;
            }
        }

        .swiper-pagination-bullets {
            bottom: 40px;

            .swiper-pagination-bullet {
                background-color: #c2c2c2;

                &.swiper-pagination-bullet-active {
                    background-color: transparent;
                    border: 2px solid #e71b23;
                    height: 12px;
                    width: 12px;
                    transform: scale(1);
                }
            }
        }
    }
}

.posts-slider-home {
    .post-slider {
        padding-bottom: 40px;
        margin-right: -16px;

        .swiper-slide {
            width: 100% !important;

            &.swiper-slide-active {
                width: 90% !important;
            }
        }

        .swiper-pagination-bullets {
            bottom: 0;

            .swiper-pagination-bullet {
                background-color: #c2c2c2;

                &.swiper-pagination-bullet-active {
                    background-color: transparent;
                    border: 2px solid #e71b23;
                    height: 12px;
                    width: 12px;
                    transform: scale(1);
                }
            }
        }
    }
}

.featured-block {
    background-color: #ffffff;
    padding: 60px 80px;
    border-radius: 8px;
    margin-top: 65px;

    @media (max-width: 600px) {
        margin-top: 20px;
        padding: 15px 20px 40px;
    }

    .stamps {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        .stamp {
            margin: 0 5px;
            width: calc(100% / 3 - 10px);
        }
    }

    .info {
        padding-left: 70px;

        @media (max-width: 600px) {
            padding: 0;
        }

        .MuiTypography-subtitle2 {
            color: #777777;
            margin-bottom: 10px;

            span {
                color: #7bc24d;
            }
        }

        .MuiTypography-body2 {
            color: #777777;
            margin-bottom: 30px;
        }
    }
}

.featured-block-two {
    margin-bottom: 120px;

    @media (max-width: 960px) {
        margin-bottom: 0;
    }

    .feature-item {
        position: relative;
        padding: 50px 0 0;
        background-size: cover;
        height: 800px;
        border-radius: 5px;

        @media (max-width: 960px) {
            height: auto;
            margin-bottom: 100px;
        }

        @media (max-width: 600px) {
            padding-bottom: 180px;
        }

        .info {
            text-align: center;
            padding: 0 50px;

            @media (max-width: 600px) {
                padding: 0 15px;
            }

            .MuiTypography-h2 {
                margin-bottom: 10px;
            }

            .MuiTypography-body2 {
                margin-bottom: 40px;
            }
        }

        .img {
            margin: 0 auto;
            display: block;
            max-width: 700px;

            @media (max-width: 960px) {
                max-width: 620px;
            }

            @media (max-width: 600px) {
                max-width: 300px;
                position: absolute;
                bottom: -70px;
                left: 50%;
                margin-left: -150px;
            }
        }

        &.item-one {
            background-color: #ffffff;

            .info {
                .btn {
                    margin-bottom: 35px;
                }
            }
        }

        &.item-two {
            * {
                color: #ffffff;
            }
        }
    }
}

.blog-items {
    // overflow: hidden;

    .card {
        text-decoration: none;
        display: block;

        .card-image {
            height: 265px;
            border-radius: 5px;
            margin-bottom: 30px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .card-info {
            .caption {
                margin-bottom: 10px;
                display: block;

                span {
                    color: #777777;
                    margin-right: 25px;
                    display: inline-block;

                    &:nth-child(2) {
                        color: #ED1C24;
                        padding-left: 25px;
                        position: relative;

                        &:before {
                            content: '';
                            display: block;
                            height: 5px;
                            width: 5px;
                            border-radius: 50%;
                            background-color: #ED1C24;
                            position: absolute;
                            top: 6px;
                            left: 0;
                        }
                    }
                }
            }

            .title-3 {
                margin-bottom: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                height: 70px;

                @media (max-width: 600px) {
                    margin-bottom: 0;
                }
            }

            .text-1 {
                color: #777777;
                margin-bottom: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;

                @media (max-width: 600px) {
                    -webkit-line-clamp: 7;
                    line-clamp: 7;
                    margin-bottom: 20px;
                }
            }

            .btn {
                @media (max-width: 600px) {
                    display: none;
                }
            }

            @media (max-width: 600px) {
                padding: 15px;
            }
        }

        @media (max-width: 600px) {
            background-color: #ffffff;
            border-radius: 5px;
        }
    }
}


.ame-qrcode-wrapper {
    width: 100%;
    margin: 40px auto;
}

.ame-qrcode-wrapper p,
.ame-qrcode-wrapper strong,
.ame-qrcode-wrapper span {
    // font-family: 'Ubuntu';
    font-size: 12px;
}

.ame-qrcode-wrapper .logo {
    display: block;
    margin: 0 auto;
}

.ame-qrcode-wrapper .container {
    margin-top: 20px;
    padding: 20px;
}

.ame-qrcode-wrapper .payment-values {
    text-align: center
}

.ame-qrcode-wrapper .payment-values strong {
    display: block;
}

.ame-qrcode-wrapper .payment-values strong:first-child {
    margin-bottom: 5px;
}

.ame-qrcode-wrapper .payment-values strong span {
    color: #ED555D;
    font-size: 16px;
}

.ame-qrcode-wrapper .payment-values p strong {
    display: inline;
    color: #4a90e2;
}

.ame-qrcode-wrapper img.qrcode {
    display: block;
    margin: 10px auto;
}

.ame-qrcode-wrapper .payment-info>strong {
    color: #ED555D;
    font-size: 16px;
    text-align: center;
    text-align: justify;
}

.ame-qrcode-wrapper .payment-info ul {
    list-style: none;
    padding: 0;
}

.ame-qrcode-wrapper .payment-info ul li {
    color: #aaaaaa;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.ame-qrcode-wrapper .payment-info ul li p {
    height: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;
}

.ame-qrcode-wrapper .payment-info ul li strong,
.ame-qrcode-wrapper .payment-info ul li img {
    margin: 0 2px
}

.ame-qrcode-wrapper a {
    color: #aaaaaa;
    font-size: 12px;
}