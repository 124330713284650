html, body {
    height: 100%;
}

body {
    font-family: 'Manrope';
    font-weight: 300;
}

.container {
    max-width: 1170px;
}

.MuiContainer-root {
    @media (min-width: 1366px) {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }
}

input[type="email"],
input[type="search"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"] {
    width: 100%;
}

textarea {
    resize: none;
}

a {
    font-family: 'Manrope';
    font-size: 16px;
    color: $color-2;

    &:hover {
        color: $color-2;
        text-decoration: none;
    }
}

p {
    font-family: 'Manrope';
    font-size: 15px;
    line-height: 25px;
    color: #777777; 
}

::placeholder {
    font-family: 'Manrope';
    font-size: 14px;
    color: #777777 !important;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: 'Manrope';
    font-size: 14px;
    color: #777777 !important;
}

::-moz-placeholder { /* Firefox 19+ */
    font-family: 'Manrope';
    font-size: 14px;
    color: #777777 !important;
}

:-ms-input-placeholder { /* IE 10+ */
    font-family: 'Manrope';
    font-size: 14px;
    color: #777777 !important;
}

:-moz-placeholder { /* Firefox 18- */
    font-family: 'Manrope';
    font-size: 14px;
    color: #777777 !important;
}
