.pagination {
    .pagination-block {
        display: flex;
        list-style: none;
        justify-content: center;
        flex-wrap: wrap;

        .page-number {
            font-size: 16px;
            font-weight: 800;
            background-color: #ffffff;
            border-radius: 50%;
            width: 55px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px;

            @media (max-width: 768px) {
                width: 30px;
                height: 30px;
                margin: 5px 3px;
            }

            .link {
                font-size: 16px;
                font-weight: 800;
                text-decoration: none;
                width: 55px;
                height: 55px;
                border-radius: 50%;
                background-color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 768px) {
                    width: 30px;
                    height: 30px;
                }

                &.active {
                    color: #ED1C24;
                    border: 3px solid #ED1C24
                }
            }

            &.page-arrow {
                font-size: 14px;
                color: #C9C9C9;
                width: auto;
                background-color: transparent;
                margin: 5px 50px;

                @media (max-width: 768px) {
                    margin: 5px;
                    width: 30px;
                }

                .link {
                    font-size: 14px;
                    color: #ED1C24;
                    background-color: transparent;
                    width: auto;
                }

                .icon {
                    margin: 0 20px;
                }

                .arrow-text {
                    @media (max-width: 768px) {
                        display: none;
                    }
                }

                .text {
                    @media (max-width: 600px) {
                        display: none;
                    }
                }
            }
        }
    }

    .MuiPagination-ul {
        li {
            &:first-child {
                margin-right: 55px;

                @media (max-width: 600px) {
                    margin-right: initial;
                }

                .MuiPaginationItem-page {
                    font-size: 14px;
                    color: #ff367d; // #ED1C24;
                    width: auto;
                    background-color: transparent;
                    border: 0;

                    &.Mui-disabled {
                        color: #C9C9C9;
                    }

                    &:after {
                        content: 'VOLTAR';
                        display: block;

                        @media (max-width: 600px) {
                            display: none;
                        }
                    }

                    .MuiPaginationItem-icon {
                        margin-right: 20px;

                        @media (max-width: 600px) {
                            margin-right: 0px;
                        }
                    }
                }
            }

            &:last-child {
                margin-left: 55px;

                @media (max-width: 600px) {
                    margin-left: initial;
                }

                .MuiPaginationItem-page {
                    font-size: 14px;
                    color: #ff367d; // #ED1C24;
                    width: auto;
                    background-color: transparent;
                    border: 0;

                    &.Mui-disabled {
                        color: #C9C9C9;
                    }

                    &:before {
                        content: 'AVANÇAR';
                        display: block;

                        @media (max-width: 600px) {
                            display: none;
                        }
                    }

                    .MuiPaginationItem-icon {
                        margin-left: 20px;

                        @media (max-width: 600px) {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }

        .MuiPaginationItem-page {
            font-weight: 800;
            color: #333333;
            width: 54px;
            height: 54px;
            border-radius: 50%;
            background-color: #ffffff;
            border: 3px solid #ffffff;
            margin: 0 5px;

            &.Mui-disabled {
                opacity: 1;
                background-color: transparent;
                border: 0;
            }

            &.Mui-selected {
                color: #ff367d; // #ED1C24;
                border-color: #ff367d; // #ED1C24;
                background-color: #ffffff;

                &:hover {
                    background-color: #ffffff;
                }
            }

            &:hover {
                background-color: #ffffff;
            }

            .MuiPaginationItem-icon {
                font-size: 30px;
            }

            @media (max-width: 600px) {
                height: 44px;
                margin: 0;
                width: 44px;
            }
        }
    }
}

.img-fluid {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.promotionalBanner {
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;

    .promotionalSub2 {
        margin-right: 10px;

        @media (max-width: 600px) {
            display: none;
        }
    }

    .promotionalH3 {
        margin-right: 30px;

        @media (max-width: 600px) {
            margin-right: 0;
        }
    }

    .promotionalBody1 {
        margin-right: 10px;
        color: #ffffff;

        @media (max-width: 600px) {
            display: none;
        }
    }

    .promotionalH5 {
        border: 2px solid #76B900;
        border-radius: 8px;
        padding: 10px 20px;

        @media (max-width: 600px) {
            display: none;
        }
    }
}

.radioGroupRed {
    .MuiButtonBase-root {
        .MuiIconButton-label {
            div {
                .MuiSvgIcon-root {
                    display: none;
                }

                &:after {
                    content: '';
                    display: block;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    background-color: transparent;
                    border: 1px solid #C9C9C9;
                }
            }
        }

        &.Mui-checked {
            .MuiIconButton-label {
                div {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        height: 6px;
                        width: 6px;
                        background-color: #ED1C24;
                        border: 2px solid #ED1C24;
                        position: absolute;
                        top: 6px;
                        left: 6px;
                        border-radius: 50%;
                    }

                    &:after {
                        border: 1px solid #ED1C24;
                        //background-color: #ED1C24;
                    }
                }
            }
        }
    }
}

.typeBox {
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 30px;
    margin-bottom: 40px;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);

    @media (max-width: 600px) {
        display: block;
        text-align: center;
        padding: 20px 0;
    }

    .boxRadio {
        @media (max-width: 600px) {
            justify-content: space-around;
        }
    }

    .typeBoxTitle {
        margin-right: 50px;

        @media (max-width: 600px) {
            margin-right: 0;
        }
    }

    .MuiFormControlLabel-root {
        margin-right: 50px;

        @media (max-width: 600px) {
            margin-right: 0;
        }

        .MuiButtonBase-root {
            .MuiIconButton-label {
                div {
                    .MuiSvgIcon-root {
                        display: none;
                    }

                    &:after {
                        content: '';
                        display: block;
                        height: 20px;
                        width: 20px;
                        border-radius: 50%;
                        background-color: transparent;
                        border: 1px solid #C9C9C9;
                    }
                }
            }

            &.Mui-checked {
                .MuiIconButton-label {
                    div {
                        position: relative;

                        &:before {
                            content: '';
                            display: block;
                            height: 4px;
                            width: 8px;
                            background-color: transparent;
                            border-bottom: 2px solid #ffffff;
                            border-left: 2px solid #ffffff;
                            position: absolute;
                            top: 7px;
                            left: 6px;
                            transform: rotate(-45deg);
                        }

                        &:after {
                            background-color: #0084F4;
                            border: 1px solid #0084F4;
                        }
                    }
                }
            }
        }

        .MuiFormControlLabel-label {
            font-weight: 800;
            color: #0084F4;
        }
    }
}

.comparisonBox {
    display: flex;
    justify-content: center;
    margin-top: 8px;

    & button.btn {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.comparisonBox,
.checkbox-red {
    .MuiButtonBase-root {
        .MuiIconButton-label {
            .MuiSvgIcon-root {
                display: none;
            }

            &:after {
                content: '';
                display: block;
                height: 18px;
                width: 18px;
                border-radius: 6px;
                background-color: transparent;
                border: 1px solid #C9C9C9;
            }
        }

        &.Mui-checked {
            .MuiIconButton-label {
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    height: 5px;
                    width: 9px;
                    background-color: transparent;
                    border-bottom: 2px solid #DB1842;
                    border-left: 2px solid #DB1842;
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    transform: rotate(-45deg);
                }


            }
        }
    }
}

.footer-default {
    .MuiTypography-body1 {
        font-size: 13px !important;
    }
}

.hidden-mobile {
    @media (max-width: 600px) {
        display: none;
    }
}

.show-mobile {
    display: none;

    @media (max-width: 600px) {
        display: block;
    }
}


.profilePage {
    padding: 10px 0 90px;

    .titleBox {
        position: relative;
        padding: 0 0 40px;
        margin-bottom: 15px;
        text-align: center;

        .loginTitle {
            font-size: 35px;
            position: relative;
            z-index: 1;
        }

        .bigTitle {
            font-family: 'Gilroy-Light';
            font-weight: 100;
            color: #EBEBEB;
            letter-spacing: -4px;
            margin-bottom: -80px;
        }
    }

    .loginOption {
        display: flex;
        background-color: #ffffff;
        border-radius: 5px;
        align-items: center;
        padding: 10px;
        margin-bottom: 20px;

        .MuiTypography-body1 {
            font-size: 12px;
            color: #333333;
        }
    }

    .btnForgetPass {
        font-size: 12px;
        color: #777777;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .btnLogin {
        font-family: 'Manrope';
        font-size: 17px;
        font-weight: 800;
        color: #ffffff;
        background-color: #ff367d; // #db1842;
        padding: 10px;
        width: 100%;
        text-transform: none;

        &:hover {
            background-color: #fe196a; // #c5153b;
        }
    }

    .registerAction {
        font-family: 'Manrope';
        font-size: 12px;
        color: #777777;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;

        .btnLink {
            font-size: 12px;
            font-weight: 800;
            color: #0084F4;
            text-transform: none;

            &::after {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                background-color: transparent;
                border-top: 1px solid #0084f4;
                border-right: 1px solid #0084f4;
                transform: rotate(40deg);
                margin-left: 5px;
            }
        }
    }

    @media (max-width: 600px) {
        padding: 10px 0 30px;
    }
}

.wp-block-gallery {
    .blocks-gallery-grid {
        list-style: none;
        display: flex;

        .blocks-gallery-item {
            padding: 5px;
        }
    }
}