.MuiAccordion-root {
    &.defaultAccordion {
        background-color: #ffffff;
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);
        border-radius: 5px !important;
        border-bottom: 1px solid #E4E4E4 !important;
        margin-bottom: 15px;
        scroll-margin-top: 100px;

        @media (max-width: 600px) {
            margin-bottom: 0;
            border-radius: 0 !important;
            box-shadow: none;
        }

        form+& {
            margin-top: 2rem;
        }

        &:last-child {
            border-bottom: 0;
        }

        &:before {
            display: none;
        }

        .panelHeader {
            padding: 30px 50px;
            min-height: auto;
            max-height: 110px;
            position: relative;

            @media (max-width: 480px) {
                padding: 30px 24px;

                h4 {
                    font-size: 18px;
                    padding-right: 32px;
                }

                &::before {
                    right: 32px !important;
                }

                &::after {
                    right: 24px !important;
                }
            }

            &:before {
                content: '';
                display: block;
                background-color: #333333;
                height: 17px;
                width: 2px;
                position: absolute;
                right: 60px;
                top: 45px;
                transition: 0.3s;
            }

            &:after {
                content: '';
                display: block;
                background-color: #333333;
                width: 18px;
                height: 2px;
                position: absolute;
                right: 52px;
                top: 52px;

                transition: 0.3s;
            }

            &.Mui-expanded {
                &::before {
                    transform: rotate(90deg);
                }

                &:after {
                    content: '';
                    display: block;
                    background-color: #333333;
                    width: 18px;
                    height: 2px;
                    position: absolute;
                    right: 52px;
                    top: 52px;
                }

                .accordionHeading {
                    &:before {
                        content: '';
                        display: block;
                        background-color: #ff367d; // #ED1C24;
                        height: 4px;
                        position: absolute;
                        bottom: 1px;
                        left: 50px;
                        width: 250px;
                        border-radius: 5px 5px 0 0;
                    }

                    &:after {
                        content: '';
                        display: block;
                        background-color: #E4E4E4;
                        height: 1px;
                        position: absolute;
                        right: 50px;
                        bottom: 0;
                        left: 50px
                    }
                }
            }

            .accordionHeading {
                color: #333333;
            }
        }

        .accordionContent {
            padding: 40px 50px;

            .itemTitle {
                font-weight: 800;
                color: #333333;
                margin-bottom: 10px;
            }

            .itemText {
                color: #777777;
                position: relative;
                padding-left: 25px;
                margin-bottom: 10px;

                &:after {
                    content: '';
                    display: block;
                    background-color: #ED1C24;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    position: absolute;
                    top: 9px;
                    left: 5px;
                }
            }

            a {
                font-weight: 700;
                color: #ED1C24;
            }
        }
    }
}

.productDescription {
    margin: 30px 0;

    @media (max-width: 600px) {
        margin: 8px 0;
    }

    .productTitleBox {
        display: flex;
        align-items: center;

        @media (max-width: 600px) {
            display: block;
        }
    }
}

.productInfo {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 40px 60px;

    .productInfoTabs {
        border-bottom: 1px solid #E4E4E4;

        .MuiTab-root {
            font-family: 'Gilroy-ExtraBold';
            font-size: 22px;
            text-transform: none;
            color: #777777;
            min-width: inherit;
            max-width: inherit;
            overflow: inherit;
            position: relative;
            opacity: 1;
            padding: 10px 0;
            margin: 0 45px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;

                &:after {
                    display: none;
                }
            }

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background-color: transparent;
                border-radius: 5px 5px 0 0;
                position: absolute;
                z-index: 1;
                transition: 0.3s;
                bottom: -4px;
            }

            &:after {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                background-color: #ED1C24;
                border-radius: 50%;
                position: absolute;
                z-index: 1;
                right: -47px;
            }

            &.Mui-selected {
                color: #333333;

                &:before {
                    background-color: #ED1C24;
                    bottom: 0;
                    left: 0;
                }
            }
        }

        .MuiTabs-indicator {
            display: none;
        }
    }

    .productInfoTabsContent {
        margin: 0 -24px;
    }
}