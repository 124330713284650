.btn {
    font-family: 'Manrope' !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    color: #ffffff !important;
    padding: 16px 40px !important;
    position: relative !important;
    border-radius: 6px !important;
    transition: 0.2s !important;
    width: max-content !important;

    &.btn-1 {
        border: 2px solid #76b900 !important;
    }

    &.btn-2 {
        border: 2px solid #ffffff !important;
    }

    &.btn-3 {
        color: #76b900 !important;
        border: 2px solid #76b900 !important;
        position: relative;

        .icon {
            position: absolute;
            right: 20px;
            top: 25px;

            @media (max-width: 600px) {
                display: none;
            }
        }
    }

    &.btn-4 {
        text-transform: none;
        background-color: #db1842 !important;
        position: relative;

        .icon {
            position: absolute;
            left: 20px;
            top: 22px;
        }
    }

    &.btn-5 {
        font-size: 11px !important;
        color: #777777 !important;
        text-decoration: none;
        background-color: transparent !important;
        padding: 10px 20px !important;
        position: relative;
    }

    &.btn-6 {
        font-size: 11px !important;
        color: #ED1C24 !important;
        background-color: transparent !important;
        padding: 10px 0 !important;
        position: relative;
        min-width: 115px !important;

        .icon {
            position: absolute;
            left: 0;
            top: 10px;
        }
    }

    &.btn-7 {
        color: #0084F4 !important;
        border: 2px solid #0084F4 !important;
    }

    &.btn-8 {
        text-transform: none !important;
        padding: 8px 20px 8px 30px !important;
        color: #333333 !important;
        border: 2px solid #333333 !important;

        .icon {
            margin-left: 10px;
            vertical-align: middle;
        }
    }

    &.btn-9 {
        font-size: 11px !important;
        color: #ffffff !important;
        background-color: transparent !important;
        padding: 10px 20px !important;
        position: relative;

        .icon {
            position: absolute;
            right: 0;
            top: 10px;
        }
    }

    &.btn-sm {
        padding: 10px 50px !important;
    }

    &.btn-back {
        font-size: 9px !important;
        color: #777777 !important;
        text-decoration: none;
        background-color: transparent !important;
        padding: 10px 0 !important;
        position: relative;
    }

    &.btn-full {
        width: 100% !important;
    }

    &.btn-social-media {
        background-color: transparent;
        border: 1px solid rgba(255,255,255,0.5);
        padding: 10px 20px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        & img {
            height: 25px;
            width: 25px;
        }
    }

    @media (max-width: 600px) {
        font-size: 14px !important;
        padding: 10px !important;
    }
}