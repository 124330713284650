.commomPage {
    &.aboutPage {
        overflow: hidden;

        .aboutInfo {
            padding-top: 180px;

            @media (max-width: 600px) {
                padding-top: 30px;
            }

            .block {
                margin-bottom: 50px;

                @media (max-width: 600px) {
                    margin-bottom: 0;
                }

                .line {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                }
            }

            .aboutImg {
                border-radius: 50%;
                filter: drop-shadow(0px 60px 100px rgba(0, 0, 0, 0.2));

                @media (max-width: 960px) {
                    max-width: 400px;
                    display: block;
                    margin: 0 auto;
                }

                @media (max-width: 600px) {
                    max-width: 300px;
                }
            }

            .aboutNote {
                @media (max-width: 960px) {
                    max-width: 400px;
                    display: block;
                    margin: 0 auto 50px;
                }

                @media (max-width: 600px) {
                    max-width: 300px;
                }
            }

            .aboutTitle {
                font-size: 60px;
                line-height: 60px;

                @media (max-width: 600px) {
                    font-size: 30px;
                    line-height: 32px;
                    text-align: center;
                    max-width: 300px;
                    margin: 0 auto;
                }
            }

            .description {
                padding: 0 80px;

                @media (max-width: 600px) {
                    padding: 0;
                }
            }
        }

        .featuresCompany {
            .item {
                margin-bottom: 50px;

                .itemTitle {
                    color: #ff367d; // #ED1C24;
                    position: relative;
                    padding-left: 80px;

                    @media (max-width: 600px) {
                        padding-left: 80px;
                        margin-bottom: 30px;
                    }

                    &:before {
                        content: '';
                        display: block;
                        width: 60px;
                        height: 3px;
                        background-color: #ff367d; // #ED1C24;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        margin-top: -3px;
                    }
                }
            }
        }

        .shops {
            .bigTitle {
                font-size: 140px;
                color: #ffffff;
                text-align: center;
                margin-bottom: -50px;

                @media (max-width: 960px) {
                    font-size: 80px;
                    line-height: 80px;
                    margin-bottom: -24px;
                }

                @media (max-width: 600px) {
                    font-size: 44px;
                    margin-bottom: -36px;
                }
            }

            .items {
                .item {
                    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);
                    border-radius: 20px;
                    background-color: #ffffff;
                    padding: 40px;

                    // @media (max-width: 600px) {
                    //     margin: 0 -12px;
                    // }

                    .item-info {
                        padding-left: 32px;

                        @media (max-width: 900px) {
                            padding-left: 0;
                        }
                    }

                    .swiper-container {
                        padding-bottom: 15px;

                        .swiper-pagination {
                            .swiper-pagination-bullet {
                                width: 8px;
                                height: 8px;
                                background-color: #C9C9C9;
                                transform: none;

                                &.swiper-pagination-bullet-active {
                                    background-color: #ff367d; // #ED1C24;
                                }
                            }
                        }

                        .swiper-button-prev {
                            top: auto;
                            left: 90px;

                            &:before {
                                content: url(../icons/pinkArrowLeft.svg);
                            }

                            &:after {
                                display: none;
                            }
                        }

                        .swiper-button-next {
                            top: auto;
                            right: 90px;

                            &:before {
                                content: url(../icons/pinkArrowRight.svg);
                            }

                            &:after {
                                display: none;
                            }
                        }
                    }

                    .block {
                        border-bottom: 1px solid #E4E4E4;
                        padding: 25px 0;

                        &:last-child {
                            border: 0;
                        }

                        .textList {
                            padding-left: 30px;
                            position: relative;

                            &:before {
                                content: '';
                                display: block;
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background-color: #ff367d; // #ED1C24;
                                position: absolute;
                                top: 6px;
                                left: 6px;
                            }
                        }

                        .btnMap {
                            font-size: 11px;
                            font-weight: 800;
                            text-transform: uppercase;
                            text-decoration: none;
                            color: #0084F4;
                            border: 2px solid #0084F4;
                            border-radius: 6px;
                            padding: 10px 15px 10px 10px;
                            display: flex;
                            max-width: max-content;
                            margin-top: 15px;

                            .icon {
                                margin-right: 10px;
                            }
                        }

                        * {
                            color: #333333;
                        }
                    }
                }
            }
        }
    }

    &.driversPage {
        .content {
            .blockDownload {
                @media (max-width: 600px) {
                    margin: 0 -16px;
                }

                .MuiTabs-root {
                    .MuiTabs-flexContainer {
                        justify-content: center;

                        .MuiButtonBase-root {
                            font-family: 'Gilroy-ExtraBold';
                            font-size: 30px;
                            line-height: 32px;
                            text-transform: none;

                            width: 230px;
                            height: 230px;
                            background-color: #ffffff;
                            border-radius: 50%;
                            background-position: center;
                            background-repeat: no-repeat;
                            opacity: 1;
                            box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);
                            margin: 0 50px 120px;
                            overflow: inherit;
                            border: 5px solid transparent;
                            position: relative;

                            @media (max-width: 600px) {
                                font-size: 20px;
                                line-height: 22px;
                                width: 100px;
                                height: 100px;
                                margin: 0 20px 120px;
                            }

                            .MuiTab-wrapper {
                                position: absolute;
                                bottom: -80px;
                            }

                            &.Mui-selected {
                                color: #ff367d; // #ED1C24;
                                border: 5px solid #ff367d; // #ED1C24;
                            }

                            &.driverItem {
                                background-image: url("../icons/driversIcon.svg");

                                @media (max-width: 600px) {
                                    background-size: 45px;
                                }

                                &.Mui-selected {
                                    background-image: url("../icons/driversIconPink.svg");

                                    &:after {
                                        content: '';
                                        display: block;
                                        width: 40px;
                                        height: 40px;
                                        background-color: #ffffff;
                                        transform: rotate(45deg);
                                        position: absolute;
                                        bottom: -145px
                                    }
                                }
                            }

                            &.manualItem {
                                background-image: url("../icons/manualsIcon.svg");

                                @media (max-width: 600px) {
                                    background-size: 55px;
                                }

                                &.Mui-selected {
                                    background-image: url("../icons/manualsIconPink.svg");

                                    &:after {
                                        content: '';
                                        display: block;
                                        width: 40px;
                                        height: 40px;
                                        background-color: #ffffff;
                                        transform: rotate(45deg);
                                        position: absolute;
                                        bottom: -145px
                                    }
                                }
                            }
                        }
                    }

                    .MuiTabs-indicator {
                        display: none;
                    }
                }

                .tabContent {
                    background-color: #ffffff;
                    max-width: 1000px;
                    width: 100%;
                    margin: 0 auto;
                    border-radius: 5px;
                    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);
                    padding: 35px;

                    @media (max-width: 600px) {
                        padding: 25px 0 0;
                        width: auto;
                        margin: 0 20px;
                    }

                    .blockFilter {
                        margin-bottom: 20px;

                        @media (max-width: 600px) {
                            padding: 0 15px;
                        }
                    }

                    .blockResult {
                        border: 1px solid #E4E4E4;
                        border-radius: 5px;

                        .resultHead {
                            background-color: #F4F4F4;
                            padding: 5px 15px;
                            color: #333333;

                            @media (max-width: 600px) {
                                padding: 30px 15px 5px;
                            }
                        }

                        .resultContent {
                            padding: 0 15px;

                            .resultLine {
                                border-bottom: 1px solid #E4E4E4;
                                padding: 15px 0;
                                color: #333333;

                                &:last-child {
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.driversPage,
    &.supportPage,
    &.faqPage,
    &.termsPage,
    &.policyPage {
        .content {
            padding-top: 80px;

            @media (max-width: 600px) {
                padding-top: 50px;
            }

            .btnBack {
                font-size: 12px;
                color: #ED1C24;
                text-decoration: none;
                position: relative;
                padding-left: 20px;
                bottom: -30px;

                &:before {
                    content: '';
                    display: block;
                    width: 4px;
                    height: 4px;
                    background-color: transparent;
                    border-bottom: 1px solid #ED1C24;
                    border-left: 1px solid #ED1C24;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 6px;
                    left: 0;
                }
            }

            .info {
                padding-bottom: 110px;
                margin-bottom: 120px;
                border-bottom: 1px solid #E4E4E4;

                @media (max-width: 1366px) {
                    padding-bottom: 30px;
                    margin-bottom: 30px;
                    border: 0;
                }

                .description {
                    text-align: center;

                    @media (max-width: 600px) {
                        text-align: left;
                    }

                    .text {
                        color: #777777;

                        @media (max-width: 600px) {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }

                    .block-title {
                        margin-bottom: 25px;

                        @media (max-width: 600px) {
                            text-align: center;
                        }
                    }
                }

                .supportTypes {
                    .item {
                        background-Color: #ffffff;
                        width: 100%;
                        height: 245px;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        align-items: flex-end;
                        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);
                        padding-bottom: 40px;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .questions {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
                padding: 120px 0;

                @media (max-width: 1366px) {
                    padding: 50px 30px;
                    margin: 0 -16px;
                }

                p {
                    @media (max-width: 600px) {
                        text-align: left;
                    }
                }

                .item {
                    display: block;
                    max-width: 220px;
                    width: 100%;
                    text-align: center;
                    height: 100%;
                    margin: 0 auto;
                    text-decoration: none;

                    @media (max-width: 900px) {
                        margin-bottom: 48px;
                    }

                    .item__image-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 80px;
                        margin-bottom: 8px;
                    }
                }
            }

            .services {
                text-align: center;
                margin-bottom: 120px;

                @media (max-width: 600px) {
                    margin-bottom: 40px;
                }

                p {
                    @media (max-width: 600px) {
                        text-align: left;
                    }
                }

                .item {
                    display: block;
                    max-width: 190px;
                    width: 100%;
                    text-align: center;
                    margin: 0 auto;
                    text-decoration: none;

                    @media (max-width: 900px) {
                        margin-bottom: 48px;
                    }

                    .item__image-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 80px;
                        margin-bottom: 8px;
                    }
                }
            }
        }

        .contactContent {
            .info {
                .description {
                    margin-bottom: 50px;
                    text-align: left;
                }

                .contactItems {
                    .item {
                        text-transform: none;
                        width: 100%;
                        background-color: #ffffff;
                        margin-bottom: 20px;
                        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);
                        justify-content: flex-start;
                        height: 100px;
                        padding: 0 40px;

                        .icon {
                            max-height: 45px;
                            margin-right: 25px;
                        }

                        &:first-child {
                            padding: 0 35px;

                            .icon {
                                max-height: 55px;
                            }
                        }

                        &:nth-child(2) {
                            .icon {
                                max-height: 40px;
                            }
                        }
                    }
                }

                .contactForm {
                    padding-left: 170px;
                    position: relative;

                    @media (max-width: 1366px) {
                        padding-left: 30px;
                    }

                    @media (max-width: 959px) {
                        padding-left: 0;
                    }

                    .contactTitle {
                        padding: 25px 0 60px;
                    }
                }
            }
        }

        .MuiAccordion-root {
            &.defaultAccordion {
                box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);
                border-radius: 5px !important;
                border-bottom: 1px solid #E4E4E4 !important;
                margin-bottom: 15px;

                .panelHeader {
                    @media (max-width: 600px) {
                        max-height: inherit;
                        padding: 30px 70px 30px 20px;
                    }

                    &.Mui-expanded {
                        .accordionHeading {
                            &:before {
                                @media (max-width: 600px) {
                                    left: 20px;
                                }
                            }

                            &:after {
                                @media (max-width: 600px) {
                                    right: 20px;
                                    left: 20px;
                                }
                            }
                        }
                    }
                }

                .accordionContent {
                    @media (max-width: 600px) {
                        padding: 30px 20px;
                    }
                }
            }
        }
    }
}