div.DraftEditor-root {
    padding: 10px;
    overflow-y: auto;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    min-height: 7em;
}

.shareLinks2:focus {
    color: red !important;
}

.shopping-cart {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}

.swiper-pagination-current {
  width: 19px !important;
  height: 19px !important;
  display: inline-block !important;
  border-radius: 100% !important;
  background: #000 !important;
  opacity: 0.7 !important;
}

.swiper-pagination-total {
  width: 19px !important;
  height: 19px !important;
  display: inline-block !important;
  border-radius: 100% !important; 
  background: #000 !important;
  opacity: 0.7 !important;
}

.swiper-pagination-fraction {
  color: white;
  font-weight: bold;
  width: 10% !important;
  left: 45% !important;
} 

.product-related-swiper-button-next {
  /* top: 45% !important; */
}

.product-related-swiper-button-prev {
  /* top: 45% !important; */
}

.swiper-button-prev, .swiper-button-next {
  color: rgba(0, 0, 0, 0.296) !important;
}