@font-face {
    font-family: 'Gilroy-ExtraBold';
    src: url('./fonts/Gilroy-ExtraBold.woff2') format('woff2'),
         url('./fonts/Gilroy-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy-Light';
    src: url('./fonts/Gilroy-Light.woff2') format('woff2'),
         url('./fonts/Gilroy-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
