.blog-items {

    .main-post,
    .featured-card {
        p {
            color: #c9c9c9;
        }
    }
}

.post-content {
    .text {
        p {
            font-size: 18px;
            margin-bottom: 35px;
        }

        h1,
        h2,
        h3,
        h4 {
            display: block;
            clear: both;
        }

        h2 {
            color: #000000;
            margin-top: 35px;
            margin-bottom: 35px;
        }

        h3 {
            color: #000000;
            margin-top: 35px;
            margin-bottom: 35px;
        }

        h4 {
            color: #000000;
            margin-top: 35px;
            margin-bottom: 35px;
        }
    }

    img {
        height: auto;
        max-width: 100%;
        aspect-ratio: auto !important;
        margin-bottom: 30px;

        @media (max-width: 599px) {
            width: 100% !important;
            max-height: 250px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            object-fit: contain !important;
        }

    }

    ul {
        padding-left: 20px;
    }

    @media (min-width: 600px) {
        .alignleft img {
            clear: left;
            float: left;

            margin-right: 30px;
            margin-bottom: 30px;
        }

        .alignright img {
            clear: right;
            float: right;
            margin-left: 30px;
            margin-bottom: 30px;
        }
    }
}

.related-items {
    .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;

        .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            background-color: #C9C9C9;
            opacity: 1;
            margin: 0 15px !important;

            &.swiper-pagination-bullet-active {
                width: 14px;
                height: 14px;
                border: 2px solid #ED1C24;
                background-color: transparent;
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 100px;
        height: 100px;
        background-color: #ffffff;
        border-radius: 50%;
        right: 0;

        &:after {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            border-top: 4px solid #333333;
            border-right: 4px solid #333333;
            transform: rotate(45deg);
        }

        &.swiper-button-disabled {
            background-color: #e4e4e4;
            opacity: 1;

            &:after {
                border-color: #c9c9c9;
            }
        }
    }

    .swiper-button-prev {
        left: 0;

        &:after {
            transform: rotate(-135deg);
        }

        &.swiper-button-disabled {
            background-color: #e4e4e4;
            opacity: 1;

            &:after {
                border-color: #c9c9c9;
            }
        }
    }
}

.avatar {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
}

.topicTags {
    display: flex;
    visibility: hidden;

    a {
        font-size: 9px;
        font-weight: 800;
        color: #333333;
        text-transform: uppercase;
        text-decoration: none;
        border-radius: 4px;
        padding: 5px 10px;
        margin: 0 5px;
        border: 1px solid #E4E4E4;
        visibility: visible;

        &:last-child {
            margin-right: 0;
        }
    }
}