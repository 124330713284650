.top-header {
    background-color: #222222;
    margin: 0 -24px;

    &--gray {
        background-color: #F4F4F4;

        .MuiButton-label {
            color: #333333;
        }

    }

    .MuiGrid-item {
        .btnTopHeader {
            font-family: $gilroyExtraBold;
            font-size: 13px;
            position: relative;
            color: #ffffff;
            background-color: transparent;
            text-transform: none;
            padding: 5px 21px 5px 15px;


            .icon {
                height: 11px;
                margin-right: 10px;
            }

            .underline {
                height: 2px;
                width: 84px;
                background-color: #ff367d; // #db1842;
                position: absolute;
                bottom: 0;
            }

            &:after {
                content: '';
                display: block;
                height: 4px;
                width: 4px;
                border-radius: 50%;
                background-color: $color-1;
                position: absolute;
                right: 0;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 899px) {
        display: none;
    }
}

.statusMenu {
    background-color: #333333;
    border-radius: 0 0 5px 5px;
    padding: 40px;
    width: 630px;

    @media (max-width: 899px) {
        width: 100%;
    }

    .MuiFormLabel-root {
        font-family: 'Manrope';
        font-weight: 800;
        font-size: 14px;
        color: #C9C9C9;
    }

    .MuiInputBase-root {
        .MuiInput-input {
            background-color: #ffffff;
        }

        &:before,
        &:after {
            display: none;
        }
    }
}

.userMenu {
    .MuiPaper-root {
        top: 95px !important;
        border-radius: 5px;
        padding: 10px 20px;
        box-shadow: none;
        left: auto !important;
        right: 100px;

        .MuiFormLabel-root {
            font-family: 'Manrope';
            font-weight: 800;
            font-size: 14px;
            color: #C9C9C9;
        }

        .MuiInputBase-root {
            .MuiInput-input {
                background-color: #ffffff;
            }

            &:before,
            &:after {
                display: none;
            }
        }
    }
}

.MuiAppBar-root {
    // .main-header {
    //     @media (max-width: 959px) {
    //         background-color: #000000;
    //     }
    // }

    // .menu-categories {
    //     height: 100%;
    //     align-items: center;
    //     display: flex;

    //     * {
    //         height: 100%;
    //     }

    //     .MuiGrid-root {
    //         .MuiGrid-item {
    //             .MuiButtonBase-root {
    //                 font-family: $gilroyExtraBold;
    //                 position: relative;
    //                 font-size: 22px;
    //                 line-height: 26px;
    //                 padding: 0 35px;
    //                 height: 100%;

    //                 &:after {
    //                     content: '';
    //                     display: block;
    //                     height: 8px;
    //                     width: 8px;
    //                     border-radius: 50%;
    //                     background-color: $color-1;
    //                     position: absolute;
    //                     right: 0;
    //                 }

    //                 &:before {
    //                     content: '';
    //                     display: block;
    //                     height: 0;
    //                     width: 131px;
    //                     border-radius: 25px 25px 0 0;
    //                     background-color: $color-1;
    //                     position: absolute;
    //                     bottom: 0;
    //                     transition: 0.2s;
    //                 }

    //                 &:hover {
    //                     &:before {
    //                         height: 5px;
    //                     }
    //                 }
    //             }

    //             &:last-child {
    //                 .MuiButtonBase-root {
    //                     &::after {
    //                         display: none;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    // .MuiBox-root {
    //     .MuiIconButton-root {
    //         padding-right: 35px;
    //         padding-left: 35px;

    //         &:after {
    //             content: '';
    //             display: block;
    //             height: 4px;
    //             width: 4px;
    //             border-radius: 50%;
    //             background-color: $color-1;
    //             position: absolute;
    //             right: 0;

    //             @media (max-width: 600px) {
    //                 display: none;
    //             }
    //         }

    //         &:last-child {
    //             &:after {
    //                 display: none;
    //             }
    //         }
    //     }
    // }
}

.MuiToolbar-root {
    position: initial !important;
}

.drawerHeader {
    // height: 48px;
    // position: relative;

    .box-close {
        background-color: #171717;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 9;
    }
}

.menu-categories {
    align-items: center;
    display: flex;
    height: 100%;

    .MuiContainer-root {
        .MuiGrid-item {
            &:last-child {
                .MuiButtonBase-root {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .level-0 {
        color: #fff;
        font-family: $gilroyExtraBold;
        font-size: 20px;
        line-height: 26px;
        height: 100%;
        padding: 16px 22px 16px 14px;
        position: relative;

        &.level-0-fixed {
            padding: 12px 24px;
        }

        &--black {
            color: #333333;
        }

        &:after {
            content: '';
            display: block;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: $color-1;
            position: absolute;
            right: 0;
        }

        &:before {
            content: '';
            display: block;
            height: 0;
            width: 100px;
            border-radius: 25px 25px 0 0;
            background-color: $color-1;
            position: absolute;
            bottom: 0;
            transition: 0.2s;
        }

        &:hover {
            &:before {
                height: 5px;
            }
        }

        @media (max-width: 936px) {
            padding: 16px 18px;
        }
    }

    .grid-level-1 {
        padding-left: 20px;
        padding-right: 20px;
        position: relative;

        @media only screen and (min-device-width: 960px) and (max-device-width: 1024px) {
            padding-left: 10px;
            padding-right: 10px;
        }

        &:after {
            background-color: #333333;
            content: '';
            height: 100%;
            left: 0;
            // margin-left: -40px;
            position: absolute;
            // transform: rotate(90deg);
            top: 0;
            width: 1px;
        }

        &:first-child {
            &:after {
                display: none;
            }
        }

        &.products {
            display: flex;
            justify-content: space-between;
            padding: 0;

            .product {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                padding: 2rem 3.75rem 0;

                &:first-child {
                    padding: 2rem 3.75rem 0 7.5rem;
                }

                &:last-child {
                    padding: 2rem 7.5rem 0 3.75rem;
                }
            }

            .product-image {
                display: block;
                height: 100%;
                margin-bottom: 10px;
                max-height: 200px;
                width: 100%;
            }

            .level-1 {
                margin: 0;
                padding: 0;
                text-align: center;

                .MuiButton-label {
                    justify-content: center;
                }
            }
        }
    }

    .level-1 {
        color: #fff;
        font-family: $gilroyExtraBold;
        font-size: 16px;
        line-height: 18px;
        padding-left: 26px;
        position: relative;
        text-align: left;
        // width: 100%;

        & div {
            display: block;
            padding-bottom: 4px;
            margin-top: 4px;
            border-bottom: 2px solid transparent;
            transition: border .3s;
        }

        &:hover div {
            border-color: #ff367d; // #db1842;
        }

        .MuiButton-label {
            justify-content: flex-start;
        }

        &:hover {
            color: #fff
        }

        .level-1-arrow {
            filter: invert(36%) sepia(42%) saturate(5038%) hue-rotate(319deg) brightness(102%) contrast(101%);
            left: 0;
            margin-top: -6px;
            position: absolute;
            top: 50%;
        }
    }

    .descubra {
        width: 100%;
    }

    .level-2 {
        color: #fff;
        // font-family: $manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        padding-bottom: 0;
        padding-left: 26px;
        padding-top: 0;
        text-transform: none;

        &:hover {
            color: #fff
        }
    }

    .notebooks {
        .featuredImage {
            margin-bottom: -50px;
        }
    }

    &.mobile {

        .grid-item {
            position: relative;

            .list-item-button {
                height: 46px;
                position: absolute;
                right: 0;
                top: 0;
            }

            .list-item-children {
                position: relative;

                .MuiListItem-button {
                    padding-left: 85px;
                }

                .list-item-button {
                    height: auto;
                    position: absolute;
                    left: 28px;
                    right: inherit;
                    top: 5px;
                    z-index: 10;
                }
            }
        }

        .level-0 {
            padding: 0 35px;

            .MuiTypography-body1 {
                color: #fff;
                font-family: $gilroyLight;
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                padding: 0;
            }

            &:after {
                margin-top: -4px;
                left: 0;
                top: 50%;
            }
        }

        .level-1 {
            font-family: $gilroyExtraBold;
            padding: 0;
            text-transform: uppercase;
        }

        .level-2-link {
            margin: 0;
            padding: 0 16px;

            .MuiTypography-body1 {
                border-bottom: 1px solid #333;
                padding: 15px 0;
            }
        }

        .level-2 {
            border-left: 2px solid #ff367d; // #ff0000;
            margin-bottom: 0;
            margin-top: 0;
        }

        .customer-links {
            background-color: #171717;
        }

        .register {
            padding-top: 30px;
            padding-bottom: 30px;
            align-items: center;
            align-content: center;
            display: flex;
            justify-content: center;

            .register-button {
                background-color: #222;
                border-radius: 6px;
                color: #fff;
                font-size: 12px;
                line-height: 14px;
                margin: 0 10px;
                padding: 10px 25px 10px 15px;

                &:last-child {
                    padding-right: 45px;
                }
            }
        }

        .links {
            .level-0 {
                margin: 0;

                .MuiTypography-body1 {
                    border-bottom: 1px solid #333;
                    color: #fff;
                    font-family: $gilroyLight;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    padding: 15px 0;
                    text-transform: none;
                }

                &:after {
                    height: 6px;
                    width: 6px;
                    margin-top: -3px;
                    left: 0;
                    top: 50%;
                }
            }

            .list-item {
                padding-bottom: 0;
                padding-top: 0;

                &.wishlist {
                    .icon {
                        height: 10px;
                        width: 10px;
                    }

                    .level-0 {
                        padding-left: 24px;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.drawer-blog {
    .MuiDrawer-paper {
        background-color: #222;
    }
}

.blog-menu {
    height: 100%;
    align-items: center;
    display: flex;

    @media (max-width: 600px) {
        height: auto;
        display: block;
    }

    * {
        height: 100%;

        @media (max-width: 600px) {
            height: auto;
        }
    }

    .MuiGrid-root {
        .MuiGrid-item {
            .MuiButtonBase-root {
                font-family: $gilroyExtraBold;
                color: #ffffff;
                position: relative;
                font-size: 22px;
                line-height: 26px;
                padding: 0 50px;
                height: 100%;

                &:after {
                    content: '';
                    display: block;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    background-color: $color-1;
                    position: absolute;
                    right: 0;
                }

                &:before {
                    content: '';
                    display: block;
                    height: 0;
                    width: 131px;
                    border-radius: 25px 25px 0 0;
                    background-color: $color-1;
                    position: absolute;
                    bottom: 0;
                    transition: 0.2s;
                }

                &:hover {
                    &:before {
                        height: 5px;
                    }
                }
            }

            &:last-child {
                .MuiButtonBase-root {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.menuList {
    .MuiPaper-root.MuiMenu-paper {
        top: 28px !important;
    }
}