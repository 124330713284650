// Colors
$color-0: #ffffff;
$color-1: #ff367d; // #ed1c24;
$color-2: #000000;
$color-3: #cecece;
$color-4: #f3f3f3;
$color-5: #3ca5ec;
$color-6: #4ec43c;
$color-7: #929292;
$color-8: #f85b59;
$color-9: #ef1d4d;
$color-10: #FFBB00;

// Fonts
$gilroyExtraBold: 'Gilroy-ExtraBold', sans-serif;
// $gilroyBold: 'Gilroy-Bold', sans-serif;
$gilroyLight: 'Gilroy-Light', sans-serif;
// $manrope: 'Manrope', sans-serif;